export const firebaseConfig = {
  apiKey: "AIzaSyBppF609QOnCzM3wg2hF01mPwmaSkGM_Os",
  authDomain: "citi-map.firebaseapp.com",
  databaseURL: "https://citi-map-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "citi-map",
  storageBucket: "citi-map.appspot.com",
  messagingSenderId: "808667774695",
  appId: "1:808667774695:web:0f7ecd1574a9b42188ec42",
  measurementId: "G-GYKQK75NWL"
};
